
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src
import * as blogolinoData from '@/blogolinoData.json';
import SidebarCategory from '@/components/SidebarCategory.vue'; // @ is an alias to /src
import SidebarAge from '@/components/SidebarAge.vue'; // @ is an alias to /src
import SidebarFilter from '@/components/SidebarFilter.vue'; // @ is an alias to /src

@Options({
  components: {
    ProductGrid,
    SidebarCategory,
    SidebarAge,
    SidebarFilter
  },
  watch: {
    $route() {
      console.log('changed');
      this.reload();
    }
  }
})
export default class Search extends Vue {
  public store = useStore();
  public productList: [unknown?] = [];
  public tipList: [unknown?] = [];
  public packetList: [unknown?] = [];
  public type;
  public query;
  public selectedCategory;
  public selectedSubcategory;
  // eslint-disable-next-line
  public selectedAge: any = null;
  public showGrid = true;
  public showAll;
  public minPrice = 0;
  public maxPrice = 0;
  public typeSort;

  submitQuery() {
    this.$router.push({
      name: 'Search',
      query: { query: this.query, type: this.type, age: this.selectedAge, category: this.selectedCategory, subcategory: this.selectedSubcategory, minPrice: this.minPrice, maxPrice: this.maxPrice }
    });
  }

  public reload() {
    this.showAll = this.$router.currentRoute.value.query.showAll;
    this.typeSort = this.$router.currentRoute.value.query.sortItems;
    this.type = this.$router.currentRoute.value.query.type;
    if (!this.type) {
      this.showAll = true;
    }
    this.selectedAge = this.$router.currentRoute.value.query.age;
    this.selectedCategory = this.$router.currentRoute.value.query.category;
    this.selectedSubcategory = this.$router.currentRoute.value.query.subcategory;
    if (this.$router.currentRoute.value.query.minPrice) {
      this.minPrice = +this.$router.currentRoute.value.query.minPrice;
    }
    if (this.$router.currentRoute.value.query.maxPrice) {
      this.maxPrice = +this.$router.currentRoute.value.query.maxPrice;
    }
    this.showGrid = false;
    setTimeout(() => {
      this.showGrid = true;
    }, 50);
  }

  get user() {
    return this.store.state.user;
  }
  get userDocument() {
    return this.store.state.userDocument;
  }
  getCategoryName(type, selectedCategory, selectedSubcategory) {
    const categories = blogolinoData.categories;
    if (type === 'products') {
      for (const category of categories) {
        if (selectedCategory === category.code) {
          if (selectedSubcategory) {
            const subcategories = category.subcategories ?? [];
            for (const subcategory of subcategories) {
              if (selectedSubcategory === subcategory.code) {
                return 'Produkte:  ' + category.name + '  -  ' + subcategory.name;
              }
            }
          }

          return 'Produkte:  ' + category.name;
        }
      }
    }

    if (type == 'tips') {
      for (const category of blogolinoData.tips) {
        if (selectedCategory === category.code) {
          return 'Tipps:' + category.name;
        }
      }
    }
    if (type == 'packets') {
      for (const category of blogolinoData.packets) {
        if (selectedCategory === category.code) {
          return 'Pakete:' + category.name;
        }
      }
    }
  }
  getAgeName(selectedAge) {
    for (const age of blogolinoData.ages) {
      if (selectedAge === age.code) {
        return 'Alter:' + age.name;
      }
    }
  }

  async created() {
    this.query = this.$router.currentRoute.value.query.query;
    this.typeSort = this.$router.currentRoute.value.query.sortItems;
    console.log(this.typeSort);
    this.type = this.$router.currentRoute.value.query.type;
    if (!this.type) {
      this.showAll = true;
    }

    this.selectedAge = this.$router.currentRoute.value.query.age;
    if (this.$router.currentRoute.value.query.maxPrice) {
      this.maxPrice = +this.$router.currentRoute.value.query.maxPrice;
    }

    if (this.$router.currentRoute.value.query.minPrice) {
      this.minPrice = +this.$router.currentRoute.value.query.minPrice;
    }

    this.selectedCategory = this.$router.currentRoute.value.query.category;
    this.selectedSubcategory = this.$router.currentRoute.value.query.subcategory;
    if (!this.type || this.type.toLowerCase() === 'all') {
      this.showAll = true;
    }
    const searchFilter = {
      query: this.query,
      type: this.type,
      selectedAge: this.selectedAge,
      selectedCategory: this.selectedCategory,
      selectedSubcategory: this.selectedSubcategory,
      showAll: this.showAll,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      typeSort: this.typeSort
    };
    this.store.commit('setSearchFilter', searchFilter);
  }
}
