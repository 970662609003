
import { Vue, Options } from 'vue-class-component';
import { useStore } from '@/store';
import app from '@/main';
import { useConfirm } from 'primevue/useconfirm';
import * as blogolinoData from '@/blogolinoData.json';
import ProductGrid from '@/components/ProductGrid.vue'; // @ is an alias to /src

@Options({
  components: {
    ProductGrid
  }
})
export default class TipPage extends Vue {
  public store = useStore();
  public tipId;
  // eslint-disable-next-line
  public tip: any = null;
  public blogshopDoc = {};
  public images: [unknown?] = [];
  public imageSrc = null;
  public confirm;
  public favoriteTip = false;
  public tipWarned = false;
  public tipLocked = false;
  public userWarningOff = false;
  public listExists = false;
  public tipOnList = false;
  public selectedList;

  public addToList(tipId) {
    if (this.listExists == true) {
      this.$router.push({ name: 'AddToList', params: { tipId: tipId, type: 'tip' } });
    } else {
      this.$router.push({ name: 'AddNewList', params: { tipId: tipId, type: 'tip' } });
    }
  }
  getCategoryName(selectedCategory) {
    for (const tip of blogolinoData.tips) {
      if (selectedCategory === tip.code) {
        return tip.name;
      }
    }
  }

  public openBlogshop() {
    this.$router.push({ name: 'Blogshop', params: { id: this.tip.userId } });
  }

  public sendMessage(userId, tipId) {
    console.log(userId, tipId);
    this.$router.push({
      name: 'WriteMessage',
      params: { receiverId: userId },
      query: { itemId: tipId, type: 'tips' }
    });
  }

  public showSelectedAge(selectedAge) {
    let age = '';
    for (const element of selectedAge) {
      for (const item of blogolinoData.ages) {
        if (element === item.code) {
          age = age + ' ' + item.name + ',';
        }
      }
    }
    return age.slice(0, -1);
  }
  public toDate() {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    if (this.tip.dates) {
      if (this.tip.dates[0] && this.tip.dates[1]) {
        const start = this.tip.dates[0].toDate().toLocaleDateString('de-DE', options);
        const end = this.tip.dates[1].toDate().toLocaleDateString('de-DE', options);
        return start + ' - ' + end;
      }
      if (this.tip.dates[0] && this.tip.dates[1] == null) {
        const start = this.tip.dates[0].toDate().toLocaleDateString('de-DE', options);
        return start;
      }
    }
  }

  public confirmTipWarning(tipId) {
    this.confirm.require({
      message: 'Möchtest du den Tipp melden, weil es verbotene Inhalte hat?',
      header: 'Tipp melden',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.tipWarning(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public goToShop() {
    const url = this.tip.link;
    window.open(url, '_blank');
  }

  public async tipWarning(tipId) {
    this.tipWarned = true;
    const item = {
      type: 'tip',
      whoSetWarning: this.user.uid,
      createdAt: new Date(),
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(tipId)
      .set(item);

    this.$toast.add({ severity: 'success', summary: 'Tipp gemeldet', detail: 'Der Tipp wurde dem Support gemeldet!', life: 3000 });
    this.$router.push({ name: 'TipPage', params: { id: tipId } });
  }
  public confirmTipLock(tipId) {
    this.confirm.require({
      message: 'Möchtest du den sperren?',
      header: 'Tipp sperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.tipLock(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public tipLock(tipId) {
    this.tipLocked = true;
    // eslint-disable-next-line
    const newTip: any = {
      locked: this.tipLocked
    };
    app.config.globalProperties.$firestore
      .collection('tips')
      .doc(tipId)
      .set(newTip, { merge: true });
  }
  public confirmTipWarningOff(tipId) {
    this.confirm.require({
      message: 'Möchtest du den Tipp wieder entsperren?',
      header: 'Tipp entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.tipWarningOff(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async tipWarningOff(tipId) {
    this.tipWarned = false;
    this.tipLocked = false;
    const locked = app.config.globalProperties.$firebase.firestore.FieldValue.delete();
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(tipId)
      .update({ locked });
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(tipId)
      .delete();
    this.$toast.add({ severity: 'success', summary: 'Tipp entsperrt', detail: 'Der Tipp wurde wieder entsperrt!', life: 3000 });
    this.$router.push({ name: 'TipPage', params: { id: tipId } });
  }
  public async confirmTipWarningOffOnlyForUser(tipId) {
    this.confirm.require({
      message: 'Möchtest du den Tipp wieder für den User entsperren?',
      header: 'Tipp für User entsperren',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.tipWarningOffOnlyForUser(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async tipWarningOffOnlyForUser(tipId) {
    this.userWarningOff = true;
    const productDocument = {
      userWarningOff: true
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(tipId)
      .set(productDocument, { merge: true });
    this.$toast.add({ severity: 'success', summary: 'Tipp für User entsperrt', detail: 'Der Tipp wurde für den User wieder entsperrt!', life: 3000 });
  }
  public async confirmTipFreeze(tipId) {
    this.userWarningOff = false;
    const tipDocument = {
      userWarningOff: false
    };
    await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(tipId)
      .set(tipDocument, { merge: true });
    this.$toast.add({ severity: 'success', summary: 'Tipp wieder gesperrt', detail: 'Der Tipp wurde wieder gesperrt!', life: 3000 });
  }

  public copyShareLink() {
    const link = location.protocol + '//' + location.host + '/tip/' + this.tipId;
    // eslint-disable-next-line
    const testingCodeToCopy: any = document.querySelector('#copy-clipboard-input')!;
    testingCodeToCopy.value = link;
    testingCodeToCopy.setAttribute('type', 'text');
    testingCodeToCopy.select();

    try {
      document.execCommand('copy');
      this.$toast.add({ severity: 'success', summary: 'Link kopiert', detail: 'Der Link wurde in deine Zwischenablage kopiert', life: 3000 });
      testingCodeToCopy.setAttribute('type', 'hidden');
    } catch (err) {
      alert('Oops, unable to copy');
    }
  }

  public async confirmDeleteTip(tipId) {
    this.confirm.require({
      message: 'Bist du sicher, dass du den Tipp löschen möchtest?',
      header: 'Tipp löschen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteTip(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }

  public async deleteTip(tipId) {
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(tipId)
      .delete();

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .update({ countTips: increment });
    this.$router.push({ name: 'MeinBlogshop' });
    this.$toast.add({ severity: 'success', summary: 'Tipp gelöscht', detail: 'Dein Tipp wurde erfolgreich gelöscht', life: 3000 });
  }

  public async likeTip(tipId) {
    // eslint-disable-next-line
    if (!this.user.uid) {
      return;
    }
    this.favoriteTip = true;
    const item = {
      type: 'tip',
      createdAt: new Date()
    };
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(tipId)
      .set(item);

    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(1);
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(this.tipId)
      .update({ countFavorite: increment });
  }
  public async dislikeTip(tipId) {
    this.favoriteTip = false;

    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('favorites')
      .doc(tipId)
      .delete();
    const increment = app.config.globalProperties.$firebase.firestore.FieldValue.increment(-1);
    await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(this.tipId)
      .update({ countFavorite: increment });
  }
  public confirmDeleteFromList(tipId) {
    this.confirm.require({
      message: 'Möchtest du den Tipp von deiner Liste entfernen?',
      header: 'Tipp entfernen',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: () => {
        this.deleteTipFromList(tipId);
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    });
  }
  public async deleteTipFromList(tipId) {
    this.tipOnList = false;
    await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.user.uid)
      .collection('lists')
      .doc(this.selectedList)
      .collection('items')
      .doc(tipId)
      .delete();
  }
  public async created() {
    this.confirm = useConfirm();
    this.loadTip();
  }

  public async loadTip() {
    this.tipId = this.$router.currentRoute.value.params.id;
    const warningItem = await app.config.globalProperties.$firestore
      .collection('warnings')
      .doc(this.tipId)
      .get();

    if (warningItem && warningItem.exists) {
      this.tipWarned = true;
    }
    if (warningItem && warningItem.exists && warningItem.data().userWarningOff === true) {
      this.userWarningOff = true;
    }

    const tipRef = await app.config.globalProperties.$firestore
      .collection('tips')
      .doc(this.tipId)
      .get();
    if (tipRef.exists) {
      this.tip = tipRef.data();
      if (this.tip.locked == true) {
        this.tipLocked = true;
      }
      this.tip?.imageUrls?.forEach(url => {
        // eslint-disable-next-line no-unreachable
        const imageItem = {
          itemImageSrc: url,
          thumbnailImageSrc: url,
          alt: 'Beschreibung'
        };
        this.images.push(imageItem);
        this.imageSrc = this.tip.imageUrls[0] ? this.tip.imageUrls[0] : null;
      });
    }
    if (this.user) {
      const itemsRef = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('lists')
        .get();
      if (itemsRef) {
        this.listExists = true;
      }
      itemsRef.docs.forEach(async item => {
        const itemOnList = await app.config.globalProperties.$firestore
          .collection('blogshops')
          .doc(this.user.uid)
          .collection('lists')
          .doc(item.id)
          .collection('items')
          .doc(this.tipId)
          .get();
        if (itemOnList.exists) {
          this.tipOnList = true;
          this.selectedList = item.id;
        }
      });
    }

    if (this.user) {
      const favoriteItem = await app.config.globalProperties.$firestore
        .collection('blogshops')
        .doc(this.user.uid)
        .collection('favorites')
        .doc(this.tipId)
        .get();
      if (favoriteItem && favoriteItem.exists) {
        this.favoriteTip = true;
      }
    }
    const blogshopRef = await app.config.globalProperties.$firestore
      .collection('blogshops')
      .doc(this.tip.userId)
      .get();
    this.blogshopDoc = blogshopRef.data();
  }
  // eslint-disable-next-line
  get user(): any {
    return this.store.state.user;
  }
  // eslint-disable-next-line
  get userDocument(): any {
    return this.store.state.userDocument;
  }
  public goToLogin(tipId) {
    this.$router.push({ name: 'Login', params: { id: tipId, before: 'TipPage' } });
  }
  public goToNewIdea() {
    this.$router.push({ name: 'NewIdea' });
  }
}
