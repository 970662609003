export const firebaseConfig = {
  apiKey: 'AIzaSyAUtbdYPnus7X1v_zOSf-S3sATXZ81lERo',
  authDomain: 'blogolino-dev.firebaseapp.com',
  databaseURL: 'https://blogolino-dev.firebaseio.com',
  projectId: 'blogolino-dev',
  storageBucket: 'blogolino-dev.appspot.com',
  messagingSenderId: '622802988454',
  appId: '1:622802988454:web:51e5895342a103efff3367'
};
export const algoliaConfig = {
  appId: "C4GJAR35TW",
  apiKey: "793e16e3119d172decb34b701c9dfb10",
};